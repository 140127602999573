import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import { IntlProvider, createIntl, createIntlCache } from 'react-intl';
import Favicon from 'react-favicon';

import messages_en from './translations/messages_en.json';
import messages_fn from './translations/messages_fn.json';
import messages_ar_AE from './translations/messages_ar_AE.json'
import messages_es_AR from './translations/messages_ar_AE.json';
import messages_ar_BH from './translations/messages_ar_BH.json';
import messages_ar_DZ from './translations/messages_ar_DZ.json';
import messages_ar_EG from './translations/messages_ar_EG.json';
import messages_ar_IQ from './translations/messages_ar_IQ.json';
import messages_ar_JO from './translations/messages_ar_JO.json';
import messages_ar_KW from './translations/messages_ar_KW.json';
import messages_ar_LB from './translations/messages_ar_LB.json';
import messages_ar_LY from './translations/messages_ar_LY.json';
import messages_ar_MA from './translations/messages_ar_MA.json';
import messages_ar_OM from './translations/messages_ar_OM.json';
import messages_ar_QA from './translations/messages_ar_QA.json';
import messages_ar_SA from './translations/messages_ar_SA.json';
import messages_ar_SD from './translations/messages_ar_SD.json';
import messages_ar_SY from './translations/messages_ar_SY.json';
import messages_ar_TN from './translations/messages_ar_TN.json';
import messages_ar_YE from './translations/messages_ar_YE.json';
import messages_de_DE from './translations/messages_de_DE.json';
import messages_es_BO from './translations/messages_es_BO.json';
import messages_es_CL from './translations/messages_es_CL.json';
import messages_es_CO from './translations/messages_es_CO.json';
import messages_es_CR from './translations/messages_es_CR.json';
import messages_es_DO from './translations/messages_es_DO.json';
import messages_es_EC from './translations/messages_es_EC.json';
import messages_es_ES from './translations/messages_es_ES.json';
import messages_es_GT from './translations/messages_es_GT.json';
import messages_es_HN from './translations/messages_es_HN.json';
import messages_es_MX from './translations/messages_es_MX.json';
import messages_es_NI from './translations/messages_es_NI.json';
import messages_es_PA from './translations/messages_es_PA.json';
import messages_es_PE from './translations/messages_es_PE.json';
import messages_es_PR from './translations/messages_es_PR.json';
import messages_es_PY from './translations/messages_es_PY.json';
import messages_es_SV from './translations/messages_es_SV.json';
import messages_es_US from './translations/messages_es_US.json';
import messages_es_UY from './translations/messages_es_UY.json';
import messages_es_VE from './translations/messages_es_VE.json';
import messages_fr_FR from './translations/messages_fr_FR.json';
import messages_it_IT from './translations/messages_it_IT.json';
import messages_ja_JP from './translations/messages_ja_JP.json';
import messages_ko_KR from './translations/messages_ko_KR.json';
import messages_pt_BR from './translations/messages_pt_BR.json';
import messages_pt_PT from './translations/messages_pt_PT.json';
import messages_th_TH_TH from './translations/messages_th_TH_TH.json';
import messages_vi_VN from './translations/messages_vi_VN.json';
import messages_zh_CN from './translations/messages_zh_CN.json';
import messages_zh_TW from './translations/messages_zh_TW.json';

const messages = {
    'en-US': messages_en,
    'en': messages_en,
    'en-GB': messages_en,
    'en-AU': messages_en,
    'en-CA': messages_en,
    'en-IN': messages_en,
    'en-NZ': messages_en,
    'en-ZA': messages_en,
    'fn': messages_fn,
    'ja-JP': messages_ja_JP,
    'ko_KR': messages_ko_KR,
    'it_IT': messages_it_IT,
    'fr_FR': messages_fr_FR,
    'de_DE': messages_de_DE,
    'zh_CN': messages_zh_CN,
    'th_TH_TH': messages_th_TH_TH,
    'zh_TW': messages_zh_TW,
    'es_AR': messages_es_AR,
    'es_BO': messages_es_BO,
    'es_CL': messages_es_CL,
    'vi_VN': messages_vi_VN,
    'es_CO': messages_es_CO,
    'es_CR': messages_es_CR,
    'es_DO': messages_es_DO,
    'es_EC': messages_es_EC,
    'es_SV': messages_es_SV,
    'es_GT': messages_es_GT,
    'es_HN': messages_es_HN,
    'es_MX': messages_es_MX,
    'es_NI': messages_es_NI,
    'es_PA': messages_es_PA,
    'es_PY': messages_es_PY,
    'es_PE': messages_es_PE,
    'es_PR': messages_es_PR,
    'es_ES': messages_es_ES,
    'es_US': messages_es_US,
    'es_UY': messages_es_UY,
    'es_VE': messages_es_VE,
    'pt_BR': messages_pt_BR,
    'pt_PT': messages_pt_PT,
    'ar_BH': messages_ar_BH,
    'ar_AE': messages_ar_AE,
    'ar_DZ': messages_ar_DZ,
    'ar_EG': messages_ar_EG,
    'ar_IQ': messages_ar_IQ,
    'ar_JO': messages_ar_JO,
    'ar_KW': messages_ar_KW,
    'ar_LB': messages_ar_LB,
    'ar_LY': messages_ar_LY,
    'ar_MA': messages_ar_MA,
    'ar_OM': messages_ar_OM,
    'ar_QA': messages_ar_QA,
    'ar_SA': messages_ar_SA,
    'ar_SD': messages_ar_SD,
    'ar_SY': messages_ar_SY,
    'ar_TN': messages_ar_TN,
    'ar_YE': messages_ar_YE,
    'ja': messages_ja_JP
}
let paths = window.location.hash.split("/");
if (paths.length > 0) {
    localStorage.setItem('tenant', paths[1] ? paths[1].toUpperCase() : '')
    localStorage.setItem('tenant-url', paths[1] ? paths[1] : '')
}
axios.defaults.headers["tenant"] = localStorage.getItem('tenant')
const language = navigator.language ? navigator.language : 'en';
const intlCache = createIntlCache();
const intl = createIntl({ locale: language, messages }, intlCache);

ReactDOM.render(
    <IntlProvider locale={language} messages={messages[language] ? messages[language] : messages['en']}
        formatNumber={value => intl.formatNumber(value)}>
        <Favicon url={`/secure-ui/themes/${localStorage.getItem('tenant')}/favicon.gif`} />
        <App />
    </IntlProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
