import React, { Component } from 'react';
import './menu.css';

class Menu extends Component {

    constructor(props) {
        super();
    }

    render() {
        const { tenantDetails } = this.props
        return (
            <div className="layout-menu" style={{ borderBottomColor: tenantDetails.primaryColor }}>
                <img src={`/secure-ui/themes/${localStorage.getItem('tenant')}/company.gif`} height='40px' width='160px' alt="Logo" style={{ objectFit: 'scale-down' }} />
            </div>
        );
    }
}
export default Menu