import React from 'react';
import './Captcha.css';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { FormattedMessage } from 'react-intl'

class Captcha extends React.Component {

    // Captcha will be prepared from the below set of numbers and Alphabets.
    charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    constructor() {
        super();
        this.ref = React.createRef();
        // state to hold the captcha alphabets.
        this.state = {
            captchaAlphabets: [],
            genratedcaptcha: '',
            inputcaptcha: '',
            message: ''
        };
    }
    componentDidMount() {
        this.prepareCaptchaString();
    }
    /* This method creates a Captcha string that the alphabets will be selected randomly 
     from the above given list of alphabets and numbers 
     and fill those random chars in captchaAlphabets. */
    prepareCaptchaString = (params) => {
        let captcha = [];
        for (var i = 0; i < 4; i++) {
            captcha[i] = this.charsArray[Math.floor(Math.random() * this.charsArray.length)];
        }
        var canvas = document.getElementById("cc")
        const ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.font = "italic bold 35px cursive";
        ctx.fillStyle = "#ffffff";
        ctx.textAlign = "center";
        ctx.fillText(captcha.join(""), canvas.width / 2, 45);
        this.setState({
            captchaAlphabets: captcha,
            genratedcaptcha: captcha.join("")
        })
    }

    updateCaptchaState(e) {
        this.setState({ inputcaptcha: e.target.value });
    }

    validateCaptcha() {
        var flag = true;
        var message = ''
        const { genratedcaptcha, inputcaptcha } = this.state;
        if (genratedcaptcha === inputcaptcha) {
            flag = true;
            message = ''
        }
        else {
            this.prepareCaptchaString();
            flag = false;
            message = 'Wrong Captcha. Try again!'
        }
        this.setState(
            {
                message: message
            }
        )
        return flag;
    }

    render() {
        const { message } = this.state
        return (
            <>
                {message !== '' ?
                    <h5>
                        <p className='wrongMessage' >
                            <FormattedMessage id="wrongCaptcha" />
                        </p>
                    </h5> : null}

                <div className='captchaMasterDiv row'>
                    <span className='captchaTextSpan col-sm-2 col-md-5 col-lg-2'>
                        <canvas id="cc" width="175%" height="65px" />
                    </span>
                    <span className='captchRefreshSpan col-sm-1 col-md-1 col-lg-1'>
                        <AutorenewIcon color='primary' className='refreshCaptch' onClick={this.prepareCaptchaString} />
                    </span>
                    <span className='captchTextAreaSpan col-sm col-md col-lg'>
                        <input type="text" value={this.state.inputcaptcha} maxLength="4"
                            onChange={this.updateCaptchaState.bind(this)}
                        />
                    </span>
                </div>

            </>
        );
    }
}
export default Captcha;