import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
const styles = ({ palette }) => ({
    button: {
        height: '30px',
        padding: '1rem',
        fontWeight: '600',
        borderRadius: '4px',
        textTransform: 'initial',
        textAlign: 'center',
        fontSize: '1rem',
        outlineStyle: 'none',
        margin: '5px',
        backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.24))',
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
        transitionDuration: '100ms',
        transitionProperty: 'background-color, background-image, box-shadow, border-color, color',
        borderWidth: '0px',
        borderStyle: 'initial',
        borderColor: 'initial',
        borderImage: 'initial'
    },
    primary: {
        color: '#ffffff',
        borderColor: palette.primary.main,
        backgroundColor: palette.primary.main,
        '&:hover': {
            color: '#ffffff',
            backgroundColor: palette.primary.main,
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.32))'
        },
        '&:active': {
            backgroundColor: "#123E4A"
        },
        '&:focus': {
            boxShadow: '#EFE3EC 0px 0px 0px 3px, rgba(0, 0, 0, 0.48) 0px 0px 0px 1px inset',
            borderColor: 'rgba(0, 0, 0, .48)'
        },
        '&:disabled': {
            backgroundColor: '#EDEFF1',
            backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.24))',
            color: '#6E787D',
            cursor: 'not-allowed'
        }
    },
    secondary: {
        color: palette.primary.main,
        border: '2px solid',
        borderColor: palette.primary.main,
        backgroundColor: '#ffffff',
        backgroundImage: 'initial',
        //padding: 'calc(1rem - 2px) calc(2rem - 2px)',
        '&:hover': {
            color: '#123E4A',
            backgroundColor: '#ffffff'
        },
        '&:active': {
            color: '#1A5178',
            border: '2px solid #2C697A',
            borderImage: 'initial',
            backgroundColor: "#D5E5EA"
        },
        '&:focus': {
            backgroundColor: '#E3ECEF',
            borderColor: '#23E4A'
        },
        '&:disabled': {
            color: '#838D90',
            backgroundColor: '#C8CFD2'
        }
    },
    containerLess: {
        color: '#277AB5',
        boxShadow: 'none',
        backgroundImage: 'none',
        fontWeight: '600',
        '&:hover': {
            backgroundColor: '#DAE7EA'
        },
        '&:active': {
            backgroundColor: '#D5E5EA',
        },
        '&:focus': {
            color: '#277ab5',
        },
    },
    selected: {
        color: '#FFFFFF',
        boxShadow: 'none',
        backgroundImage: 'none',
        backgroundColor: '#277ab5',
        '&:hover': {
            backgroundColor: '#DAE7EA',
            color: '#277ab5'
        },
        '&:active': {
            backgroundColor: '#D5E5EA',
        }
    }
});

function CPButton(props) {
    const { classes, children, id, disabled, styles, onClick, type, variant, title } = props
    return (
        <Button id={id} title={title} className={`${classes.button} ${classes[variant]}`} style={styles} type={type} onClick={onClick} disabled={disabled}>{children}</Button>
    )
}

export default withStyles(styles)(CPButton);