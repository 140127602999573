import React, { useState, useMemo } from 'react';
import './App.css';
import 'primereact/resources/themes/nova-light/theme.css';
import BaseComponent from './components/landing/LandingPage'
import { initialError, ErrorProvider } from './common/error'
import { getThemeDetails, getHost } from './sevices/TrackingService';
import { createTheme, ThemeProvider } from '@material-ui/core';


function App(props) {

  const setErrormsg = (message, code) => {
    setError({ ...error, msg: message, code: code })

  }
  const [error, setError] = useState({ ...initialError, setErrormsg: setErrormsg })
  const [details, setdetails] = useState({ primaryColor: '', secondaryColor: '' })
  if (details.primaryColor === '') {
    getHost().then(res => {
      // /* for deployment or commit */
      getThemeDetails().then(data => {
        setdetails({ ...data })

      })
      /* for local devlopment */
      // let newdetails = require(`./sevices/${localStorage.getItem('tenant')}.json`)
      // setdetails({ ...newdetails })
    }).catch(err => {

    })
  }
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: 'open sans',
          body1: {
            fontSize: '14px'
          }
        },
        palette: {
          primary: {
            main: details.primaryColor ? details.primaryColor : '#417f90'
          },
          secondary: {
            main: details.secondaryColor ? details.secondaryColor : '#1a5178'
          },
          text: {
            primary: '#4A4E50'
          }
        }
      }),
    [details]
  );
  return (
    details.baseSystem !== undefined && details.baseSystem !== '' ?
      <ThemeProvider theme={theme}>
        <ErrorProvider value={error}>
          <BaseComponent tenantDetails={details} />
        </ErrorProvider>
      </ThemeProvider>
      : null
  );
}
export default App;
