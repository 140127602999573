import React from 'react'
import { Progress } from 'reactstrap';
import "../common/ProgressBar.css"
import { useIntl } from 'react-intl';


function ProgressBar(props) {
    const { status } = props;

    const intl = useIntl();
    const progressArray = {
        "PICKED UP":
            [
                { "header": intl.formatMessage({ id: 'pickedUp' }), "className": "statusPickUp" },
                { "header": intl.formatMessage({ id: 'departed' }), "className": "statusDefault" },
                { "header": intl.formatMessage({ id: 'arrived' }), "className": "statusDefault" },
                { "header": intl.formatMessage({ id: 'released' }), "className": "statusDefault" },
            ],
        "PICKUP":
            [
                { "header": intl.formatMessage({ id: 'pickedUp' }), "className": "statusPickUp" },
                { "header": intl.formatMessage({ id: 'departed' }), "className": "statusDefault" },
                { "header": intl.formatMessage({ id: 'arrived' }), "className": "statusDefault" },
                { "header": intl.formatMessage({ id: 'released' }), "className": "statusDefault" },
            ],
        "DEPARTED":
            [
                { "header": intl.formatMessage({ id: 'pickedUp' }), "className": "statusDeparted" },
                { "header": intl.formatMessage({ id: 'departed' }), "className": "statusDeparted" },
                { "header": intl.formatMessage({ id: 'arrived' }), "className": "statusDefault" },
                { "header": intl.formatMessage({ id: 'released' }), "className": "statusDefault" },
            ],
        "ARRIVED":
            [
                { "header": intl.formatMessage({ id: 'pickedUp' }), "className": "statusArrived" },
                { "header": intl.formatMessage({ id: 'departed' }), "className": "statusArrived" },
                { "header": intl.formatMessage({ id: 'arrived' }), "className": "statusArrived" },
                { "header": intl.formatMessage({ id: 'released' }), "className": "statusDefault" },
            ],
        "RELEASED":
            [
                { "header": intl.formatMessage({ id: 'pickedUp' }), "className": "statusReleased" },
                { "header": intl.formatMessage({ id: 'departed' }), "className": "statusReleased" },
                { "header": intl.formatMessage({ id: 'arrived' }), "className": "statusReleased" },
                { "header": intl.formatMessage({ id: 'released' }), "className": "statusReleased" },
            ],

    }
    return (
        <div >
            {progressArray[status ? status.toUpperCase() : ''] ?
                <Progress className="progressBars" multi>
                    {progressArray[status.toUpperCase()].map((el, index) => (<Progress key={index} bar className={`progressBar ${el.className}`} value='25' >{el.header}</Progress>))}
                </Progress> :
                null}
        </div>
    )
}

export default ProgressBar
