import React from 'react';
import './dataTable.css';
import { Table, TableHead, TableRow, TableCell, TableBody, Collapse, makeStyles } from '@material-ui/core'
import { getStatusClass } from '../../common/utils'
import ProgressBar from "../common/ProgressBar"
import TreeTable from "../common/TreeTable"
import { FormattedMessage } from 'react-intl'


const dataTableColoumns = [{ "header": "Tracking Number", "field": "jobNo" },
{ "header": "Status", "field": "currentStatus" },
{ "header": "Carrier", "field": "carrier" },
{ "header": "Service", "field": "bizType" },
{ "header": "Shipment Type", "field": "loadTerm" },
{ "header": "Weight", "field": "weight" },
{ "header": "Pieces", "field": "totalPcs" },
{ "header": "Est. Delivery", "field": "devryETDDate" },
{ "header": "Destination", "field": "estdDelDate" },
{ "header": "HBL/HAWB/Direct BL No.", "field": "shpNo" }]
// { "header": "MBL/MAWB No.", "field": "devryETADate" }]


const milstoneColoumns = [{ "header": "Date", "field": "eventDate" },
{ "header": "Time", "field": "eventTime" },
{ "header": "Location", "field": "location" },
{ "header": "Status", "field": "eventCode" },
{ "header": "Notes", "field": "type" }]

const useStyles = makeStyles({
    tableHeader: {
        fontWeight: 'bold !important',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        borderBottom: '4px solid #CDE3E9',
        height: '54px',
        padding: '6px 6px 6px 10px'
    },
    tabletr: {
        display: ' table !important',
        width: '100%',
        fontSize: '14px',
        tableLayout: 'fixed',
        margin: 0,
        fontFamily: "'Open Sans', Arial, sans-serif !important",
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        '&:nth-child(even)': {
            backgroundColor: '#F3F7F9'
        }
    },
    tableCell: {
        fontSize: '14px',
        border: '1px solid #DAE7EA',
        height: '48px',
        padding: '6px 6px 6px 10px',
        borderLeft: '0px'
    }
})

export const TrackingTable = (props) => {
    const classes = useStyles();

    const { data, handleRowClick, handleRowClickContainer, selectedOption, tenantDetails } = props;

    const { numFormatByLang } = props;
    return (
        <Table size="small" style={{ overflowX: 'auto' }}>
            <TableHead>
                <TableRow className={classes.tabletr}>
                    {
                        dataTableColoumns.map((coulumn, index) => {
                            return (<TableCell className={classes.tableHeader} key={index}><FormattedMessage id={coulumn.header} /></TableCell>)

                        })
                    }
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    data ?
                        data.map((shipment, shipmentIndex) => {
                            return (
                                <React.Fragment key={shipmentIndex}>
                                    <TableRow style={{ cursor: "pointer" }} className={classes.tabletr} onClick={() => handleRowClick(shipmentIndex)}>
                                        <TableCell className={classes.tableCell}> <span className="hyperlink" style={{ color: tenantDetails.secondaryColor }}>
                                            {selectedOption === "JobNo" ? `Job No. ${shipment.jobNo ? shipment.jobNo : ""}`
                                                // : selectedOption === "MasterNo" ? `Master No. ${shipment.masterNo ? shipment.masterNo : ''}`
                                                : selectedOption === "ShpNos" ? `Shipment No. ${shipment.shpNo ? shipment.shpNo : ''}`
                                                    : selectedOption === "BookingNos" ? `Booking No. ${shipment.bookingNo ? shipment.bookingNo : ''}`
                                                        : selectedOption === "ContainerNo" ? `Container No. ${shipment.containerNo ? shipment.containerNo : ''}`
                                                            : selectedOption === "RefNos" ? `Ref No.  ${shipment.refNo ? shipment.refNo : ''}` : ''} </span></TableCell>
                                        <TableCell className={classes.tableCell}><span className={`${shipment.currentStatus ? 'progressStatus' + getStatusClass(shipment.currentStatus) : ''}`}></span>{shipment.currentStatus}</TableCell>
                                        <TableCell className={classes.tableCell}> {shipment.carrier} </TableCell>
                                        <TableCell className={classes.tableCell}> {shipment.bizType === 'SE' || shipment.bizType === 'SI' ? 'Sea' : shipment.bizType === 'AE' || shipment.bizType === 'AI' ? 'Air' : shipment.bizType === 'IN' ? 'Inland' : shipment.bizType} </TableCell>
                                        <TableCell className={classes.tableCell}> {shipment.loadTerm} </TableCell>
                                        <TableCell className={classes.tableCell}>{
                                            // selectedOption === "MasterNo" ? '': 
                                            selectedOption === "ContainerNo" ? (numFormatByLang(shipment.totWgt) ? shipment.totWgt + (shipment.totWgtUt ? ' ' + shipment.totWgtUt : '') : '')
                                                : (shipment.totGWgt ? numFormatByLang(shipment.totGWgt) + (shipment.totGWgtUt ? ' ' + shipment.totGWgtUt : '') : '')}</TableCell>
                                        {/* <Tooltip classes={{ tooltip: classes.tooltip }} title="Times are shown in the local timezone. "> */}
                                        <TableCell className={classes.tableCell}>{
                                            // selectedOption === "MasterNo" ? '':
                                            (shipment.totPcs ? numFormatByLang(shipment.totPcs) + (shipment.totPcsUt ? ' ' + shipment.totPcsUt : '') : '')}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}> {shipment.devryEtaDate} </TableCell>
                                        {/* </Tooltip> */}
                                        <TableCell className={classes.tableCell}>{shipment.hasOwnProperty('devryName') ? shipment.devryName + ',' : ''}
                                            {shipment.hasOwnProperty('devryCity') ? shipment.devryCity : ''}</TableCell>
                                        <TableCell className={classes.tableCell}> {shipment.shpNo ? shipment.shpNo : ''} </TableCell>
                                        {/* <TableCell className={classes.tableCell}> {shipment.masterNo} </TableCell> */}
                                    </TableRow>

                                    {/* <TableRow className={classes.tabletr} >
                                                    <TableCell className={classes.tableCell} colSpan={10}> */}
                                    <Collapse className="containerBody" in={shipment.expandedRow} timeout="auto" unmountOnExit>
                                        <ProgressBar statusClass={getStatusClass(shipment.currentStatus)} status={shipment.currentStatus ? shipment.currentStatus : null} />
                                        <TreeTable treeTableColoumns={milstoneColoumns} data={selectedOption === "ContainerNo" ? (shipment.containerStatusList ? shipment.containerStatusList : []) : (shipment.statusList ? shipment.statusList : [])} type={selectedOption === "ContainerNo" ? "containers" : ""} />

                                        {/* </TableCell>
                                                </TableRow> */}
                                        {shipment.hasOwnProperty("containers") ?
                                            <TableRow className={classes.tabletr}>
                                                <TableCell className={classes.tableCell} colSpan={10}>
                                                    <div className="containerBody table-responsive-sm">
                                                        <h6 className={classes.tableHeader}> <FormattedMessage id="Container Number" /></h6>
                                                        <div>
                                                            {shipment.containers.map((container, index) => {
                                                                return (<><TableRow key={index} className={classes.tabletr} style={{ cursor: "pointer" }} onClick={() => handleRowClickContainer(shipmentIndex, index)}>
                                                                    <TableCell colSpan={10} >
                                                                        <span className="hyperlink" style={{ color: tenantDetails.secondaryColor }}>{`Container No. ${container.containerNo}`}</span>
                                                                    </TableCell>
                                                                </TableRow>
                                                                    {container.expandedRow ?
                                                                        <Collapse in={container.expandedRow} timeout="auto" unmountOnExit>
                                                                            {/* <ProgressBar statusClass={getStatusClass(shipment.currentStatus)} status={shipment.currentStatus} /> */}
                                                                            <TreeTable treeTableColoumns={milstoneColoumns} data={container.containerStatusList ? container.containerStatusList : []} type="containers" />
                                                                        </Collapse> : null}</>)
                                                            })}

                                                        </div>
                                                    </div>
                                                </TableCell>
                                            </TableRow>
                                            :
                                            null}

                                    </Collapse>
                                </React.Fragment>
                            )
                        }) :
                        <TableRow>
                            <TableCell className="noRecords" colSpan={10}>No Records Found</TableCell>
                        </TableRow>
                }
            </TableBody>
        </Table >
    );
}
