import React from 'react'
import { Card, CardContent, withStyles } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { processDate } from '../../common/utils';


const style = theme => ({
    line: {
        margin: 0,
        display: 'flex'
    },
    title: {
        fontWeight: 'bold',
        width: '30%',
        display: 'flex',
        padding: '1%'
    },
    titleHeading: {
        fontWeight: 'bold',
        width: '100%',
        display: 'flex',
        padding: '1%',
        fontSize: '14px !important'
    },
    value: {
        width: '70%',
        display: 'inline-block',
        padding: '1%',
        fontWeight: 400,
        fontSize: '14px !important'
    },
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%'
    },
    cardRoot: {
        padding: '0 0 0 0 !important',
        marginBottom: '10px !important'
    },
    odd: {
        backgroundColor: 'rgba(237, 239, 239, 1)'
    },
    even: {
        backgroundColor: 'white'
    },
    box: {
        fontSize: '20px',
        fontWeight: '700',
        background: '#c2e7f7',
        padding: '2%'
    }
})
const MilestoneTableMobileview = (props) => {
    const { data, classes, intl, type } = props;
    const getOddorEven = (index) => {
        return index % 2 === 0 ? "even" : "odd"
    }
    return (
        <Card className={classes.root} variant="outlined" >
            <div className={classes.box}>
                Milestones
            </div>
            {data && data.length > 0 ?
                data.map((milistone, index) => {
                    return (
                        <CardContent key={index} className={`${classes.cardRoot} ${classes[getOddorEven(index)]}`}>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.titleHeading}> <span >{type === "containers" ? milistone.ctnEventName : milistone.eventName}</span></div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.title}> <span >{intl.formatMessage({ id: 'Date' })}</span></div>
                                <div className={classes.value}>
                                    <span >
                                        {type === "containers" ?
                                            processDate(milistone.ctnEventDate, "Date")
                                            : processDate(milistone.eventDate, "Date")}
                                    </span>
                                </div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.title}> <span >{intl.formatMessage({ id: 'Time' })}</span></div>
                                <div className={classes.value}>
                                    <span >
                                        {type === "containers" ?
                                            processDate(milistone.ctnEventTime, "Time")
                                            : processDate(milistone.eventTime, "Time")}
                                    </span>
                                </div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.title}> <span >{intl.formatMessage({ id: 'Location' })}</span></div>
                                <div className={classes.value}>
                                    <span >
                                        {type === 'containers' ?
                                            milistone.hasOwnProperty('ctnLocation') ? milistone.ctnLocation + ',' + (milistone.hasOwnProperty('ctnLocationCity') ? milistone.ctnLocationCity : '') : ''
                                            :
                                            milistone.hasOwnProperty('location') ? milistone.location + ',' + (milistone.hasOwnProperty('locationCity') ? milistone.locationCity : '') : ''
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                <div className={classes.title}> <span >{intl.formatMessage({ id: 'Notes' })}</span></div>
                                <div className={classes.value}>
                                    <span>
                                        {type === "containers" ?
                                            milistone.ctnRemarks
                                            : milistone.remarks}
                                    </span>
                                </div>
                            </div>
                        </CardContent>

                    )
                })
                :
                <p style={{ textAlign: 'center', marginTop: '5%' }}>{intl.formatMessage({ id: 'noRecordsFound' })} </p>
            }
        </Card>
    )
}

export default injectIntl(withStyles(style)(MilestoneTableMobileview))
