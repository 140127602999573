import React from 'react'
import { Card, withStyles, CardContent } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import { getStatusClass, processDate } from '../../common/utils';
import MilestoneTableMobileview from './MilestoneTableMobileview';
// import Box from '../common/Box';
// import DetailedMileStoneData from './DetailedMileStoneData';

const style = theme => ({
    root: {
        minWidth: 275,
        width: '98%',
        margin: '1%'
    },
    cardRoot: {
        padding: '0 0 0 0 !important',
        marginBottom: '10px !important'
    },
    line: {
        margin: 0,
        display: 'flex'
    },
    titleNormal: {
        fontWeight: 'Normal',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '14px !important'
    },
    title: {
        fontWeight: 'Bold',
        width: '40%',
        display: 'flex',
        padding: '1%',
        fontSize: '14px !important'
    },
    value: {
        width: '60%',
        display: 'inline-block',
        padding: '1%',
        fontSize: '14px !important'
    },
    odd: {
        backgroundColor: 'rgba(237, 239, 239, 1)'
    },
    even: {
        backgroundColor: 'white'
    },
    noRecordsFound: {
        padding: '2% !important',
        textAlign: 'center'
    },
    box: {
        fontSize: '20px',
        fontWeight: '700',
        background: '#c2e7f7',
        padding: '2%'
    }
})

const DataTableMobileview = (props) => {
    const { data, classes, intl, handleRowClick, handleRowClickContainer, selectedOption, tenantDetails } = props;
    const getOddorEven = (index) => {
        return index % 2 === 0 ? "even" : "odd"
    }
    const { numFormatByLang } = props;
    return (
        <Card className={classes.root} variant="outlined" >
            {
                data ? data.map((shipment, shipmentIndex) => {
                    return (
                        <div key={shipmentIndex} >
                            <CardContent key={shipmentIndex} className={`${classes.cardRoot} ${classes[getOddorEven(shipmentIndex)]}`} onClick={() => handleRowClick(shipmentIndex)}>
                                <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                    <div className={classes.title}> <span >{intl.formatMessage({ id: 'Tracking Number' })}</span></div>
                                    <div className={classes.value}>
                                        <span className="hyperlink" style={{ color: tenantDetails.secondaryColor }}>
                                            {selectedOption === "JobNo" ? `Job No. ${shipment.jobNo ? shipment.jobNo : ""}`
                                                // : selectedOption === "MasterNo" ? `Master No. ${shipment.masterNo ? shipment.masterNo : ''}`
                                                : selectedOption === "ShpNos" ? `Shipment No. ${shipment.shpNo ? shipment.shpNo : ''}`
                                                    : selectedOption === "BookingNos" ? `Booking No. ${shipment.bookingNo ? shipment.bookingNo : ''}`
                                                        : selectedOption === "ContainerNo" ? `Container No. ${shipment.containerNo ? shipment.containerNo : ''}`
                                                            : selectedOption === "RefNos" ? `Ref No. ${shipment.refNo ? shipment.refNo : ''}` : ''}
                                        </span>
                                    </div>
                                </div>
                                <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                    <div className={classes.title}> <span >{intl.formatMessage({ id: 'Status' })}</span></div>
                                    <div className={classes.value}> <span className={`${shipment.currentStatus ? 'progressStatus' + getStatusClass(shipment.currentStatus) : ''}`}></span>{shipment.currentStatus}</div>
                                </div>
                                <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                    <div className={classes.title}> <span >{intl.formatMessage({ id: 'Carrier' })}</span></div>
                                    <div className={classes.value}> <span >{shipment.carrier}</span></div>
                                </div>
                                <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                    <div className={classes.title}> <span >{intl.formatMessage({ id: 'Service' })}</span></div>
                                    <div className={classes.value}> <span >{shipment.bizType === 'SE' || shipment.bizType === 'SI' ? 'Sea' : shipment.bizType === 'AE' || shipment.bizType === 'AI' ? 'Air' : shipment.bizType === 'IN' ? 'Inland' : shipment.bizType}</span></div>
                                </div>
                                <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                    <div className={classes.title}> <span >{intl.formatMessage({ id: 'Shipment Type' })}</span></div>
                                    <div className={classes.value}> <span >{shipment.loadTerm}</span></div>
                                </div>
                                <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                    <div className={classes.title}> <span >{intl.formatMessage({ id: 'Weight' })}</span></div>
                                    <div className={classes.value}> <span >{
                                        //selectedOption === "MasterNo" ? '' :
                                        selectedOption === "ContainerNo" ? (shipment.totWgt ? numFormatByLang(shipment.totWgt) + (shipment.totWgtUt ? ' ' + shipment.totWgtUt : '') : '')
                                            : (shipment.totGWgt ? numFormatByLang(shipment.totGWgt) + (shipment.totGWgtUt ? ' ' + shipment.totGWgtUt : '') : '')} </span></div>
                                </div>
                                <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                    <div className={classes.title}> <span >{intl.formatMessage({ id: 'Pieces' })}</span></div>
                                    <div className={classes.value}> <span >
                                        {
                                            // selectedOption === "MasterNo" ? '' :
                                            (shipment.totPcs ? numFormatByLang(shipment.totPcs) + (shipment.totPcsUt ? ' ' + shipment.totPcsUt : '') : '')}
                                    </span></div>
                                </div>
                                <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                    <div className={classes.title}> <span >{intl.formatMessage({ id: 'Est. Delivery' })}</span></div>
                                    <div className={classes.value}> <span >{shipment.devryEtaDate}</span></div>
                                </div>
                                <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                    <div className={classes.title}> <span >{intl.formatMessage({ id: 'Destination' })}</span></div>
                                    <div className={classes.value}>
                                        <span >
                                            {shipment.hasOwnProperty('devryName')
                                                ? shipment.devryName + ',' : ''}
                                            {shipment.hasOwnProperty('devryCity')
                                                ? shipment.devryCity : ''}
                                        </span></div>
                                </div>
                                <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                    <div className={classes.title}> <span >{intl.formatMessage({ id: 'HBL/HAWB/Direct BL No.' })}</span></div>
                                    <div className={classes.value}> <span >{shipment.shpNo ? shipment.shpNo : ''}</span></div>
                                </div>
                                {/* <div className={`${classes.line} `} style={{ border: "0px", borderBottom: "none", }}>
                                    <div className={classes.title}> <span >{intl.formatMessage({ id: 'MBL/MAWB No.' })}</span></div>
                                    <div className={classes.value}> <span > {shipment.masterNo}</span></div>
                                </div> */}
                            </CardContent>
                            {shipment.expandedRow ?
                                <>
                                    {/* <ProgressBar statusClass={getStatusClass(shipment.currentStatus)} status={shipment.currentStatus ? shipment.currentStatus : null} /> */}
                                    <MilestoneTableMobileview data={selectedOption === "ContainerNo" ? (shipment.containerStatusList ? shipment.containerStatusList : []) : (shipment.statusList ? shipment.statusList : [])} type={selectedOption === "ContainerNo" ? "containers" : ""} milistone={true} />
                                    {shipment.hasOwnProperty("containers") ?
                                        <>
                                            <h6 className={classes.box}> {intl.formatMessage({ id: 'Container Number' })}</h6>
                                            {shipment.containers.map((container, index) => {
                                                return (
                                                    <CardContent>
                                                        <div key={index} style={{ cursor: "pointer" }} hover onClick={() => handleRowClickContainer(shipmentIndex, index)}>
                                                            <span className="hyperlink" style={{ color: tenantDetails.secondaryColor }}>{`Container No. ${container.containerNo}`}</span>
                                                        </div>
                                                        {container.expandedRow ?
                                                            <div >
                                                                {/* <ProgressBar statusClass={getStatusClass(shipment.currentStatus)} status={shipment.currentStatus} /> */}
                                                                <MilestoneTableMobileview data={container.containerStatusList ? container.containerStatusList : []} type="containers" />
                                                            </div> : null}
                                                    </CardContent>)
                                            })}
                                        </> : null}
                                </>
                                :
                                null
                            }
                        </div>
                    )
                }) : <CardContent className={classes.noRecordsFound}>No Records Found</CardContent>
            }

        </Card>
    )
}

export default injectIntl(withStyles(style)(DataTableMobileview))
