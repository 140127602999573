import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const CPSnackBar = (props) => {
  const [state, setState] = React.useState({
    open: props.open,
    vertical: 'top',
    horizontal: 'center',
  });

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  const { vertical, horizontal, open } = state

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      anchorOrigin={{ vertical, horizontal }}
      key={`${vertical},${horizontal}`}
      onClose={handleClose}
    >
      <Alert onClose={handleClose}
        severity={(!props.severity || props.severity === null) ? 'success' : props.severity}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default CPSnackBar