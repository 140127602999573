import moment from 'moment';

export const processDate = (input, resultRequired) => {
    let tempdate = "";
    let dayformat = "dddd YYYY/MM/DD";
    let timeformat = "hh:mm a";
    let dateformat = "YYYY/MM/DD";
    if (resultRequired === 'Date') {
        tempdate = moment(input, "YYYYMMDD").format(dayformat)
    } else if (resultRequired === 'Time') {
        tempdate = moment(input, "HHmmss").format(timeformat)
    } else {
        tempdate = moment(input, "YYYYMMDD").format(dateformat)
    }
    return tempdate !== "Invalid date" ? tempdate : "--";
}

export const updateProgress = (item) => {
    const { selectedItem } = item;
    const x = 0;
    let progressBars = {}
    if (selectedItem[x].data.desc === 'Picked up') {
        progressBars = [{ 'Pickup': '100' }]
    }
    else if (selectedItem[x].data.desc === 'Departed from DHL hub') {
        progressBars = {
            pickup: 100,
            departed: 100
        }

    }
    else if (selectedItem[x].data.desc === 'Arrived at DHL localition') {
        progressBars = [{ 'pickup': '100' },
        { 'departed': '100' },
        { 'arrived': '50' }]


    }
    else if (selectedItem[x].data.desc === 'Arrived at DHL localition') {
        progressBars = [
            { 'pickup': '100' },
            { 'departed': '100' },
            { 'arrived': '100' }]
    }
    else if (selectedItem[x].data.desc === 'Released to Customer') {

        progressBars = [
            { 'pickup': '100' },
            { 'departed': '100' },
            { 'arrived': '100' },
            { 'relased': '100' }
        ]
    }
    return progressBars;
}
export const getStatusClass = (status) => {
    let statusClass = ""
    switch (status ? status.toUpperCase() : '') {
        case 'PICKUP':
            statusClass = " statusPickUp"
            break;
        case 'PICKED UP':
            statusClass = " statusPickUp"
            break;
        case 'ARRIVED':
            statusClass = " statusArrived"
            break;
        case 'DEPARTED':
            statusClass = " statusDeparted"
            break;
        case 'RELEASED':
            statusClass = " statusReleased"
            break;
        case 'DELAYED':
            statusClass = " statusDelayed"
            break;
        default:
            break;
    }

    return statusClass
}