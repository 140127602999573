import React, { useState } from 'react'
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import "./TreeTable.css";
import { processDate } from '../../common/utils';
import { FormattedMessage } from 'react-intl';
import { withStyles } from '@material-ui/core'


const style = {
    tableHeader: {
        fontWeight: 'bold !important',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        borderBottom: '4px solid #CDE3E9',
        height: '54px',
        padding: '6px 6px 6px 10px'
    },
    tabletr: {
        display: ' table !important',
        width: '100%',
        fontSize: '14px',
        tableLayout: 'fixed',
        margin: 0,
        fontFamily: "'Open Sans', Arial, sans-serif !important",
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        '&:nth-child(even)': {
            backgroundColor: '#F3F7F9'
        }
    },
    tableCell: {
        fontSize: '14px',
        border: '1px solid #DAE7EA',
        height: '48px',
        padding: '6px 6px 6px 10px',
        borderLeft: '0px'
    }
}

function TreeTable(props) {
    const { data, treeTableColoumns, type, classes } = props;
    const [showallRows, setshowallRows] = useState(false)
    let lastindex = data.length - 1
    return (
        <div styles={{marginLeft: '1rem'}}>
            <Table size="small" className="table-responsive-sm" >
                <TableHead>
                    <TableRow className={classes.tabletr} colSpan={10}>
                        {
                            treeTableColoumns.map((coulumn, index) => {
                                return (<TableCell className={classes.tableHeader} key={index}><FormattedMessage id={coulumn.header} /></TableCell>)

                            })
                        }
                    </TableRow>
                </TableHead>
                <TableBody className="treetablebody">
                    {data.length > 0 ?
                        data.length > 3 ?
                            showallRows ?
                                <>
                                    {
                                        data.map((milistone, index) => {
                                            return (
                                                <TableRow className={classes.tabletr} key={index} >
                                                    <TableCell className={classes.tableCell}>
                                                        {type === "containers" ?
                                                            processDate(milistone.ctnEventDate, "Date")
                                                            : processDate(milistone.eventDate, "Date")} </TableCell>
                                                    <TableCell className={classes.tableCell}> {type === "containers" ? processDate(milistone.ctnEventTime, "Time") : processDate(milistone.eventTime, "Time")} </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                        {type === 'containers' ?
                                                            milistone.hasOwnProperty('ctnLocation') ? milistone.ctnLocation + ',' + (milistone.hasOwnProperty('ctnLocationCity') ? milistone.ctnLocationCity : '') : ''
                                                            :
                                                            milistone.hasOwnProperty('location') ? milistone.location + ',' + (milistone.hasOwnProperty('locationCity') ? milistone.locationCity : '') : ''
                                                        }

                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}> {type === "containers" ? milistone.ctnEventName : milistone.eventName} </TableCell>
                                                    <TableCell className={classes.tableCell}> {type === "containers" ? milistone.ctnRemarks : milistone.remarks} </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                    < TableRow className={classes.tabletr}>
                                        <TableCell className={classes.tableCell}>
                                            <span className="linkbutton" onClick={() => { setshowallRows(!showallRows) }} > Show Less</span>
                                        </TableCell>
                                    </TableRow>
                                </>
                                :
                                <>
                                    <TableRow className={classes.tabletr}>
                                        <TableCell className={classes.tableCell}> {type === "containers" ? processDate(data[0].ctnEventDate, "Date") : processDate(data[0].eventDate, "Date")} </TableCell>
                                        <TableCell className={classes.tableCell}> {type === "containers" ? processDate(data[0].ctnEventTime, "Time") : processDate(data[0].eventTime, "Time")} </TableCell>
                                        <TableCell className={classes.tableCell}> {
                                            type === "containers" ?
                                                data[0].hasOwnProperty('ctnLocation') ? data[0].ctnLocation + ',' + (data[0].hasOwnProperty('ctnLocationCity') ? data[0].ctnLocationCity : '') : ''
                                                :
                                                data[0].hasOwnProperty('location') ? data[0].location + ',' + (data[0].hasOwnProperty('locationCity') ? data[0].locationCity : '') : ''}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}> {type === "containers" ? data[0].ctnEventName : data[0].eventName} </TableCell>
                                        <TableCell className={classes.tableCell}> {type === "containers" ? data[0].ctnRemarks : data[0].remarks} </TableCell>
                                    </TableRow>
                                    <TableRow cclassName={classes.tabletr}>
                                        <TableCell className={classes.tableCell}>
                                            <span className="linkbutton" onClick={() => setshowallRows(!showallRows)} >Show More</span>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tabletr}>
                                        <TableCell className={classes.tableCell}> {type === "containers" ? processDate(data[lastindex].ctnEventDate, "Date") : processDate(data[lastindex].eventDate, "Date")} </TableCell>
                                        <TableCell className={classes.tableCell}> {type === "containers" ? processDate(data[lastindex].ctnEventTime, "Time") : processDate(data[lastindex].eventTime, "Time")} </TableCell>
                                        <TableCell className={classes.tableCell}> {
                                            type === "containers" ?
                                                data[lastindex].hasOwnProperty('ctnLocation') ? data[lastindex].ctnLocation + ',' + (data[lastindex].hasOwnProperty('ctnLocationCity') ? data[lastindex].ctnLocationCity : '') : ''
                                                :
                                                data[lastindex].hasOwnProperty('location') ? data[lastindex].location + ',' + (data[lastindex].hasOwnProperty('locationCity') ? data[lastindex].locationCity : '') : ''}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}> {type === "containers" ? data[lastindex].ctnEventName : data[lastindex].eventName} </TableCell>
                                        <TableCell className={classes.tableCell}> {type === "containers" ? data[lastindex].ctnRemarks : data[lastindex].remarks} </TableCell>
                                    </TableRow>
                                </>
                            :

                            data.map((milistone, index) => {
                                return (
                                    <TableRow className={classes.tabletr} key={index} >
                                        <TableCell className={classes.tableCell}>
                                            {type === "containers" ?
                                                processDate(milistone.ctnEventDate, "Date")
                                                : processDate(milistone.eventDate, "Date")} </TableCell>
                                        <TableCell className={classes.tableCell}> {type === "containers" ? processDate(milistone.ctnEventTime, "Time") : processDate(milistone.eventTime, "Time")} </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {type === 'containers' ?
                                                milistone.hasOwnProperty('ctnLocation') ? milistone.ctnLocation + ',' + (milistone.hasOwnProperty('ctnLocationCity') ? milistone.ctnLocationCity : '') : ''
                                                :
                                                milistone.hasOwnProperty('location') ? milistone.location + ',' + (milistone.hasOwnProperty('locationCity') ? milistone.locationCity : '') : ''
                                            }

                                        </TableCell>
                                        <TableCell className={classes.tableCell}> {type === "containers" ? milistone.ctnEventName : milistone.eventName} </TableCell>
                                        <TableCell className={classes.tableCell}> {type === "containers" ? milistone.ctnRemarks : milistone.remarks} </TableCell>
                                    </TableRow>
                                )
                            })


                        :
                        <TableRow>
                            <TableCell className="noRecordsContainer" colSpan={treeTableColoumns.length}>No Records Found</TableCell>
                        </TableRow>
                    }

                </TableBody>
            </Table >

        </div >
    )
}

export default (withStyles(style)(TreeTable))
