import React, { Component } from 'react';
import CPButton from '../common/CPButton';
import { TrackingTable } from '../dataTable/DataTable';
import Captcha from '../captcha/Captcha';
import Menu from '../menu/Menu';
import './landingPage.css';
import { getAnonymousUser } from '../../sevices/TrackingService';
import CpDropDown from '../common/Dropdown';
import { Grid, FormHelperText, withStyles, IconButton, Tooltip, TextareaAutosize } from '@material-ui/core';
import { injectIntl } from 'react-intl';
import DataTableMobileview from '../mobileComponents/DataTableMobileview';
import CPSnackBar from '../common/CPSnackBar';
import { ReactComponent as Copy } from '../../images/copy.svg'

const style = theme => ({
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    },
    sectionMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    icon: {
        fontSize: '24px',
        color: '#417f90'
    },
    proccessing: {
        margin: "0 0 0 40%",
        fontWeight: "600",
        fontSize: '20px',
        color: '#417f90'
    },
    fieldContent: {
        display: 'block',
        lineHeight: 'calc(4rem - 4px)',
        padding: '0 1rem',
        position: 'relative',
        verticalAlign: 'middle',
        backgroundColor: '#FFFFFF',
        border: '2px solid #C8CFD2',
        borderRadius: '4px',
        color: '#4A4E50',
        transitionDuration: '100ms',
        transitionProperty: 'border-color, background-color, color, box-shadow',
        '&:focus': {
            outlineWidth: '0',
            borderColor: theme.palette.primary.main,
            boxShadow: '0 0 0 3px #e3ecef'
        },
        '&focus-visible': {
            outline: '-webkit-focus-ring-color auto 1px'
        },
        width: '90%',
        [theme.breakpoints.up('md')]: {
            width: '100%'
        }
    }
})
const trackingOptions = [
    { value: 'JobNo', label: 'Job No.' },
    { value: 'ShpNos', label: 'HBL/HAWB/Direct BL No.' },
    { value: 'BookingNos', label: 'Booking No.' },
    { value: 'ContainerNo', label: 'Container No.' },
    { value: 'RefNos', label: 'Ref No.' }
]


//Parent Component with state initialized to capture user input and set status for rendering child
let paths = window.location.hash.split("/");
let searchParam = ''
let searchParamValue = ''
if (paths && paths[2] !== undefined && paths[2].includes('?')) {
    const urlParams = new URLSearchParams(paths[2])
    for (const [key, value] of urlParams) {
        searchParam = key;
        searchParamValue = value;
    }
    window.location.href = window.location.href.replace(`/${paths[2]}`, '')
}
let userData = null;
class BaseComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableButtons: false,
            renderChild: false,
            input: "",
            trackingData: null,
            selectedRow: null,
            selectedRowContainer: null,
            expandedrow: false,
            showCaptcha: this.props.tenantDetails && this.props.tenantDetails.captchaCount ? this.props.tenantDetails.captchaCount === 1 ? true : false : false,
            captchaCounter: 1,
            status: 200,
            errormsg: undefined,
            searchOption: trackingOptions[0],
            shipmentRetrivalLink: '',
            showSnackBar: false,
            snackBarMsg: ''
        };

    }
    componentDidMount = async () => {
        if (searchParam !== '' && searchParamValue !== '') {
            let option = await trackingOptions.filter(obj => {
                return obj.value === searchParam
            })
            await this.setState({
                searchOption: option && option.length > 0 ? option[0] : {},
                input: searchParamValue,
                disableButtons: true
            })
            this.fetchShipments(true)
        }
    }
    //number format based on browser language
    numFormatByLang = (val) => {
        const { intl } = this.props;
        return intl.formatNumber(val);
    }
    //captures change in textArea
    handleChange = (e) => {
        this.setState({ input: e.target.value, errormsg: this.getErrormsg(e.target.value) });
    }

    //removes chip from textArea on clicking cross (X) on chip
    handleRemove = (e) => {
        this.setState({ renderChild: false });
    }

    //renderTable is invoked by child (TrackingTable) once the data fetching is successfull/failed
    renderTable = (input) => {
        this.setState({ renderChild: input });
    }
    updateContainerdata = (containersArray) => {
        return containersArray.map(container => {
            return { ...container, expandedRow: false }
        })

    }
    handleSelectchange = (event) => {
        this.clearAll();
        this.setState({
            searchOption: event.target.value
        })
    }
    removeSpaces = (string) => {
        return string.replace(/\s*,\s*/g, ",").trim();
    }
    validateInput = (input) => {
        let regexp = /^[a-zA-Z0-9 ,/-]*$/;
        const length = input.split(",").length
        if (input === '') {
            return false
        }
        else if (length > 20) {
            return false
        }
        else if (regexp.test(input)) {
            return true
        }
        else {
            return false
        }

    }
    getErrormsg = (input) => {
        let regexp = /^[a-zA-Z0-9 ,/-]*$/;
        const { intl } = this.props
        const length = input.split(",").length
        if (input === '') {
            return intl.formatMessage({ id: 'requiredErrormsg' })
        }
        else if (length > 20) {
            return intl.formatMessage({ id: 'moreThan20Errormsg' })
        }
        else if (regexp.test(input)) {
            return ''
        }
        else {
            return intl.formatMessage({ id: 'regularexpErrormsg' })
        }

    }
    fetchShipments = async () => {
        const { input, captchaCounter, searchOption } = this.state;
        let retrivalURL = ''
        const result = await getAnonymousUser(this.removeSpaces(input), searchOption.value);
        await this.setState({
            shipmentRetrivalLink: ''
        })
        if (result && (result.status === 200 || result.status === 204)) {
            if (result.status === 200) {
                retrivalURL = `${window.location.protocol + '//' + window.location.host + window.location.pathname + '#'}/${localStorage.getItem('tenant-url')}/?${searchOption.value}=${input}`
                await this.setState({ shipmentRetrivalLink: retrivalURL })
            }
            userData = await result.data;
            this.setState({
                disableButtons: false,
                errormsg: undefined,
                captchaCounter: captchaCounter + 1,
                status: result.status,
                trackingData: userData.shipments ? userData.shipments : '',
                renderChild: true,
                showCaptcha: false
            })

            if (userData.shipments) {
                let selectedShipments = []
                let updatedUserData = userData.shipments.map(data => {
                    let containers = data.hasOwnProperty("containers") ? this.updateContainerdata(data.containers) : null
                    let updateddata = ""
                    containers !== null ? updateddata = { ...data, expandedRow: false, containers: containers } : updateddata = { ...data, expandedRow: false }
                    return updateddata;
                })

                if (searchOption.value === "ContainerNo") {
                    updatedUserData.forEach(shipment => {
                        let selectedcontainershipments = shipment.containers.map(container => {
                            return { ...shipment, ...container }
                        })
                        selectedShipments = [...selectedShipments, ...selectedcontainershipments]
                        return { ...selectedcontainershipments[0] }

                    })
                    selectedShipments.forEach(shipment => delete shipment.containers)
                }
                this.setState({ trackingData: searchOption.value === "ContainerNo" ? selectedShipments : updatedUserData, expandedrow: false, captchaCounter: captchaCounter + 1 });
            }
        } else {
            this.setState({
                disableButtons: false,
                errormsg: undefined,
                captchaCounter: captchaCounter + 1,
                status: result ? result.status : 500,
                trackingData: null,
                renderChild: false,
                showCaptcha: false
            })
        }
    }
    //on clicking GO, validates captcha input and set child rendering flag
    handleSubmit = async (event) => {
        if (event) event.preventDefault();
        const { input, captchaCounter } = this.state;
        const { tenantDetails } = this.props
        if (this.c) {
            if (this.c.validateCaptcha() && this.validateInput(input)) {
                this.setState({
                    disableButtons: true
                })
                this.fetchShipments()
            }
            else {
                this.setState({
                    renderChild: false,
                    errormsg: this.getErrormsg(input)
                });

            }
        }
        else {
            if (captchaCounter % (tenantDetails.captchaCount ? tenantDetails.captchaCount : 5) === 0) {
                this.setState({
                    showCaptcha: true,
                    disableButtons: false
                })
            }
            else if (this.validateInput(input)) {
                this.setState({
                    disableButtons: true
                })
                this.fetchShipments()
            }
            else {
                this.setState({
                    renderChild: false,
                    errormsg: this.getErrormsg(input)
                });

            }
        }
    }
    handleRowClick = (inputIndex) => {
        const { trackingData } = this.state;
        const updatedTrackingData = trackingData.map((data, index) => {
            let expandedRow = false;
            if (index === inputIndex) {
                expandedRow = !data.expandedRow;
            }
            return { ...data, expandedRow: expandedRow }
        })
        this.setState({
            selectedRow: inputIndex,
            trackingData: updatedTrackingData
        })
    }
    handleRowClickContainer = (shipmentIndex, containerIndex) => {
        const { trackingData } = this.state;
        const updatedContainerData = trackingData[shipmentIndex].containers.map((data, index) => {
            let expandedRow = false;
            if (index === containerIndex) {
                expandedRow = !data.expandedRow;
            }
            return { ...data, expandedRow: expandedRow }
        })
        let updatedTrackingData = [...trackingData];
        updatedTrackingData[shipmentIndex].containers = updatedContainerData
        this.setState({
            selectedRow: containerIndex,
            trackingData: updatedTrackingData
        })
    }
    tryAgainHandler = () => {

        this.setState({ status: 200 })
        this.handleSubmit()
    }
    clearAll = () => {
        this.setState({
            trackingData: null,
            input: "",
            renderChild: false,
            status: 200,
            shipmentRetrivalLink: ''
        })
    }
    copyToClipBoard = async (url) => {
        const { intl } = this.props
        await this.setState({ showSnackBar: false, snackBarMsg: '' })
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(url);
            const msg = intl.formatMessage({ id: 'copy_shipment_link_msg' }, { val: this.state.input })
            await this.setState({ showSnackBar: true, snackBarMsg: msg })
        } else {
            let inputc = document.body.appendChild(document.createElement("input"));
            inputc.value = url
            inputc.select();
            document.execCommand('copy');
            inputc.parentNode.removeChild(inputc);
            const msg = intl.formatMessage({ id: 'copy_shipment_link_msg' }, { val: this.state.input })
            await this.setState({ showSnackBar: true, snackBarMsg: msg })
        }
    }
    getSnackBar = (message, open, severity) => {
        return (<CPSnackBar open={open} message={message} severity={severity}> </CPSnackBar>);
    }
    render() {
        const { trackingData, showCaptcha, input, status, searchOption, errormsg, disableButtons, shipmentRetrivalLink, showSnackBar, snackBarMsg } = this.state;
        const { tenantDetails, intl, classes } = this.props
        return (
            <>
                <Menu tenantDetails={tenantDetails} />
                <div className="maincontainer">
                    {disableButtons ? <span className={classes.proccessing}>{intl.formatMessage({ id: 'processsing' })}</span> : null}
                    {!(status === 200 || status === 204) ? <span style={{ margin: "0 0 0 40%", fontWeight: "600", fontSize: '15px', color: 'red' }}>{intl.formatMessage({ id: 'error_somethingWentWrong' })}</span> : null}
                    {showSnackBar ? this.getSnackBar(snackBarMsg, true, 'info') : null}
                    <form className="layout" onSubmit={this.handleSubmit}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={2}>
                                <CpDropDown sendLabel={true} items={trackingOptions} onChange={(event) => this.handleSelectchange(event)} value={searchOption} />
                            </Grid>
                            <Grid item xs={8} lg={8}>
                                <TextareaAutosize maxLength={620} minRows={2} placeholder={intl.formatMessage({ id: 'placeHolder' })} value={input} wrap="virtual" onChange={this.handleChange} className={classes.fieldContent}></TextareaAutosize>
                                {errormsg ? <FormHelperText error={true}>{errormsg}</FormHelperText> : null}
                                <CPButton variant='containerLess' disabled={disableButtons} onClick={this.clearAll} styles={{ fontSize: '12px' }}>{intl.formatMessage({ id: 'clearButton' })}</CPButton>

                            </Grid>
                            <Grid item lg={2} xs={2}>
                                <CPButton variant='primary' disabled={disableButtons} type="submit" value="submit" onClick={() => { this.setState({ status: 200 }) }} >{intl.formatMessage({ id: 'goButton' })}</CPButton>
                                {shipmentRetrivalLink && shipmentRetrivalLink !== '' ?
                                    <Tooltip title={intl.formatMessage({ id: 'copy_shipment_link' })} arrow>
                                        <IconButton id="link" style={{ marginLeft: '2%' }} onClick={() => { this.copyToClipBoard(shipmentRetrivalLink) }}>
                                            <Copy className={classes.icon} />
                                        </IconButton>
                                    </Tooltip>
                                    : null}
                            </Grid>

                        </Grid>
                        <div>
                            {showCaptcha ? <Captcha ref={c => this.c = c} /> : null}
                        </div>
                    </form>

                    <hr style={{ border: `1px solid ${tenantDetails.primaryColor ? tenantDetails.primaryColor : '#009CDE'}`, backgroundColor: tenantDetails.primaryColor ? tenantDetails.primaryColor : '#009CDE' }}></hr>
                    {this.state.renderChild ?
                        <>
                            <div className={`table-responsive ${classes.sectionDesktop}`}>
                                <TrackingTable handleRowClick={this.handleRowClick} selectedOption={searchOption.value} data={trackingData} handleRowClickContainer={this.handleRowClickContainer} tenantDetails={tenantDetails} numFormatByLang={this.numFormatByLang} />
                            </div>
                            <div className={classes.sectionMobile}>
                                <DataTableMobileview data={trackingData} selectedOption={searchOption.value} tenantDetails={tenantDetails} handleRowClick={this.handleRowClick} handleRowClickContainer={this.handleRowClickContainer} numFormatByLang={this.numFormatByLang} />
                            </div>
                        </>
                        : null}
                </div>
            </>
        );
    }
}

export default injectIntl(withStyles(style)(BaseComponent));